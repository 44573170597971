.timelineHighlight {
  transition: background-color 0.3s ease;
}

.timelineHighlight:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.timelineButtonHighlight {
  transition: background-color 0.3s ease;
}

.timelineButtonHighlight:hover {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 50%;
}
